<template>
  <div>
    <h1>Thank you for visiting RateRabbit Administration.</h1>
    <br>
    <h1>You have been logged out!</h1>
  </div>
</template>

<script>
  import Auth from '@/services/AuthService'

  export default {
    mounted() {
      Auth.logout();
    },
    metaInfo() {
      return {
        title: this.title,
        meta: [
          { name: 'robots', content: 'noindex, nofollow' }
        ]
      }
    }
  }
</script>
